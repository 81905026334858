const loadedScripts: { [key: string]: Promise<void> | true } = {}

/** Loads a script with an attached id, if a script with this id is already loaded/loading,
 * it will not load the script again, and return a promise that resolves when the script has loaded
 */
export function loadScriptOnce(opts: LoadScriptOptions) {
  let promise = loadedScripts[opts.id]
  if (!promise) {
    // currently not in use
    // console.log('loadScript', opts.id, loadedScripts)
    loadedScripts[opts.id] = true
    promise = _loadScript(opts)
    loadedScripts[opts.id] = promise
  }
  return promise
}

export interface LoadScriptOptions {
  src: string
  id: string
}
export function _loadScript({ src, id }: LoadScriptOptions) {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    script.onload = () => resolve()

    script.onerror = reject
    script.setAttribute('id', id)
    script.setAttribute('async', 'async')
    document.querySelector('head')?.appendChild(script)
  })
}
