import { getAdDeviceType } from '../../../client'
import { AdS, IItem, IPageMeta, ITag } from '../../../types'
import { ILevels } from '../Models/PlayerModels'
import { playerConfig } from './constants'
import { utils } from './utils'

export interface ICustParams {
  platform: 'desktop' | 'mobile' | 'webview_app'
  type_of_page: IPageMeta['pageType']
  level1: IPageMeta['levels']['level_1_eng']
  level2: IPageMeta['levels']['level_2_eng']
  level3: IPageMeta['levels']['level_3_eng']
  level4: IPageMeta['levels']['level_4_eng']
  level5: IPageMeta['levels']['level_5_eng']
  level6: IPageMeta['levels']['level_6_eng']
  tag: string
  view: string
  permutive: string
  reshet: string
  environment: string
}

export type CustParamKey = keyof ICustParams

export const dfpUtils = (() => {
  return {
    DFPParams: () => {
      return {
        platform_key: 'platform',
        platform_value: !utils.isMobile()
          ? 'desktop'
          : playerConfig.inWebView
          ? 'webview_app'
          : 'mobile',
      }
    },

    urlToDfp: (url: string) => {
      const res = url.replace(/\//g, ' ').trim()
      return res === '' ? 'Hp' : res
    },

    geAssetKey: (country: string, videoRef: string) => {
      const ASEET_KEY = playerConfig.DFPParams.ASEET_KEY
      if (videoRef === 'stream_reshet_live1_token') {
        return ASEET_KEY['Token']
      } else {
        const location = country === 'Israel' ? 'Israel' : 'Global'
        const site_val = 'tv'
        const device = playerConfig.inWebView
          ? 'a'
          : !utils.isMobile()
          ? 'd'
          : 'm'
        return ASEET_KEY[location][site_val][device + '_key']
      }
    },

    getCustParams: (
      pageType: string,
      levels: ILevels,
      DFPType: string,
      pageViewedInDay: number,
      item: IItem | undefined,
      googlePal?: string
    ) => {
      const env = utils.getEnv()
      const DFP_PARAMS = dfpUtils.DFPParams()
      let tagVal = ''
      if (item !== undefined) {
        tagVal = utils.getParamsString(item.tags)
        tagVal = tagVal.replace(/,/g, '%26')
      }
      const adUnitObj = {
        url: window.location.pathname,
        environment: env !== 'prod' ? 'reshet_staging' : 'reshet',
        key: 'reshet',
      }

      const keyValuesDFP = [
        { key: adUnitObj.key, value: dfpUtils.urlToDfp(adUnitObj.url) },
        { key: DFP_PARAMS.platform_key, value: DFP_PARAMS.platform_value },
        { key: 'type_of_page', value: pageType },
        {
          key: 'level1',
          value: levels.level_1_eng ? levels.level_1_eng : null,
        },
        {
          key: 'level2',
          value: levels.level_2_eng ? levels.level_2_eng : null,
        },
        {
          key: 'level3',
          value: levels.level_3_eng ? levels.level_3_eng : null,
        },
        {
          key: 'level4',
          value: levels.level_4_eng ? levels.level_4_eng : null,
        },
        // {
        //   key: 'level5',
        //   value: levels.level_5_eng ? levels.level_5_eng : null,
        // },
        // {
        //   key: 'level6',
        //   value: levels.level_6_eng ? levels.level_6_eng : null,
        // },
        { key: 'tag', value: tagVal },
        { key: 'permutive', value: localStorage.getItem('_psegs') },
      ]
      const idxsegvalue = localStorage.getItem(
        'dmp-publisher-prevDefinitionsIds'
      )
      if (idxsegvalue) {
        keyValuesDFP.push({
          key: 'dxseg',
          value: idxsegvalue,
        })
      }
      const dmpid = localStorage.getItem('dmp-publisher-dmpid')
      if (dmpid) {
        keyValuesDFP.push({
          key: 'dxu',
          value: dmpid,
        })
      }
      if (googlePal) {
        keyValuesDFP.push({
          key: 'pal',
          value: googlePal,
        })
      }

      if (
        pageViewedInDay &&
        pageViewedInDay <
          playerConfig.fakeWindow.siteConfig.adds.dayViewMaxCount
      ) {
        keyValuesDFP.push({ key: 'VIEW', value: 'view' + pageViewedInDay })
      }

      const length = keyValuesDFP.length
      let dfpstr = '',
        keyVal,
        val
      for (const param in keyValuesDFP) {
        // eslint-disable-next-line no-prototype-builtins
        if (keyValuesDFP.hasOwnProperty(param)) {
          keyVal = keyValuesDFP[param].key
          val = keyValuesDFP[param].value

          let equal = '%3D',
            ampersand = '%26',
            comma = '%2C'

          if (DFPType === 'midrollLive') {
            equal = '='
            ampersand = '&'
            comma = ','
          }

          if (keyVal === 'permutive' && val) {
            val = val.replace(/[[\]]+/g, '')
            val = val.replace(/,/g, comma)
          }

          dfpstr =
            dfpstr +
            keyVal +
            equal +
            val +
            (length === +param + 1 ? '' : ampersand)
        }
      }
      return dfpstr
    },

    getAdVideo: (
      pagetypeMeta: string,
      pageType: string,
      adtype: string,
      levels: ILevels,
      pageViewedInDay: number,
      item: IItem | undefined,
      googlePal?: string
    ) => {
      const ADS_VIDEO = playerConfig.DFPParams.ADS_VIDEO
      const site_val = 'tv'
      const device = playerConfig.inWebView
        ? 'a'
        : !utils.isMobile()
        ? 'd'
        : 'm'
      const ad = adtype !== '' ? '_' + adtype : ''
      const VMAPStr = device + '_' + pageType + ad
      const custParams = dfpUtils.getCustParams(
        pagetypeMeta,
        levels,
        adtype,
        pageViewedInDay,
        item,
        googlePal
      )
      return ADS_VIDEO[site_val][VMAPStr] + '&cust_params=' + custParams
    },

    AdVidDur: (duration: number) =>
      'video_' + Math.floor(duration / (60 * 3)) * 3,

    parseAdsIntoCollection: (vmapUrl: string) => {
      const parser = new DOMParser()
      const ads: AdS[] = []

      return fetch(vmapUrl)
        .then(result => result.text())
        .then(data => {
          const xmlDoc = parser.parseFromString(data, 'application/xml')
          const prerollTags = xmlDoc.getElementsByTagName('Preroll')

          for (let i = 0; i < prerollTags.length; i += 2) {
            const adTag = prerollTags[i].getElementsByTagName('Ad')[0]
            if (adTag) {
              const adContent = adTag.textContent
              ads.push({ preroll: { ad: adContent } })
            }
          }

          const midrollTags = xmlDoc.getElementsByTagName('Midroll')
          const offsetTime = parseInt(
            utils.getParameterByName('vmap_offset_time')
          )
          for (let i = 0; i < midrollTags.length; i++) {
            const timeOffset = midrollTags[i].getAttribute('timeOffset')
            const adTag = midrollTags[i].getElementsByTagName('Ad')[0]
            if (adTag) {
              const adContent = adTag.textContent
              ads.push({
                midroll: {
                  timeOffset: timeOffset
                    ? dfpUtils.timeStringToSeconds(
                        offsetTime && offsetTime > 0
                          ? '00:0' + offsetTime * (i + 1) + ':00'
                          : timeOffset
                      )
                    : null,
                  ad: adContent,
                },
              })
            }
          }
          return ads
        })
    },

    timeStringToSeconds: (timeString: string) => {
      if (timeString) {
        const [hours, minutes, seconds] = timeString.split(':').map(Number)
        return hours * 3600 + minutes * 60 + seconds
      }
      return null
    },

    buildCustParams: (
      pageType: IPageMeta['pageType'] | undefined,
      levels: IPageMeta['levels'] | undefined,
      route: string,
      tags?: ITag[] | undefined,
      adTargetEnv?: string
    ) => {
      const cust_params = {} as ICustParams
      cust_params.platform =
        getAdDeviceType() === 'desktop'
          ? 'desktop'
          : getAdDeviceType() === 'mobile'
          ? 'mobile'
          : 'webview_app'
      pageType && (cust_params.type_of_page = pageType)
      levels?.level_1_eng && (cust_params.level1 = levels.level_1_eng)
      levels?.level_2_eng && (cust_params.level2 = levels.level_2_eng)
      levels?.level_3_eng && (cust_params.level3 = levels.level_3_eng)
      levels?.level_4_eng && (cust_params.level4 = levels.level_4_eng)
      // levels?.level_5_eng && (cust_params.level5 = levels.level_5_eng)
      // levels?.level_6_eng && (cust_params.level6 = levels.level_6_eng)

      if (tags) {
        cust_params.tag = tags
          .filter(tag => tag)
          .map(tag => tag.name)
          .join(',')
      }
      if (utils.getPageViewedInDay().pageViewedInDay < 5) {
        cust_params.view = utils.getPageViewedInDay().pageViewedInDay.toString()
      }
      const routeItems = route
        .split('?')[0]
        .split('/')
        .filter(p => p !== '')
      if (routeItems.length > 0) {
        cust_params.reshet = `${routeItems
          .slice(1, routeItems.length)
          .join(' ')}`
      }
      if (adTargetEnv) {
        cust_params.environment = adTargetEnv
      }
      const psegs = localStorage.getItem('_psegs')
      if (psegs) {
        cust_params.permutive = psegs
      }

      return cust_params
    },

    setAdTargeting: (
      pageType: IPageMeta['pageType'] | undefined,
      levels: IPageMeta['levels'] | undefined,
      route: string,
      tags?: ITag[] | undefined,
      AD_TARGET_ENV?: string
    ) => {
      if (!window.googletag) {
        console.warn('googletag is not defined')
        return
      }

      const cust_params = dfpUtils.buildCustParams(
        pageType,
        levels,
        route,
        tags,
        AD_TARGET_ENV
      )
      if (!cust_params) {
        return
      }

      window.googletag.cmd.push(() => {
        window.googletag.pubads().clearTargeting()

        Object.entries(cust_params).forEach(([key, value]) => {
          window.googletag.pubads().setTargeting(key, value)
        })
      })
    },
  }
})()
