import { IPage, IOTTPage } from '../types'
import { getUuid } from '.'

type event_name =
  | 'Reshet_page_load'
  | 'Open_warstrip'
  | 'App_page_load'
  | `Video_${
      | 'video_start_live'
      | 'content_start_live'
      | 'video_start_1010'
      | 'content_start_1010'
      | `video_reach_${number}`}`
  | 'Live_watching_duration'
  | 'Embeded_video_start_1010'
  | 'OttReshet_page_load'
  | 'OttApp_page_load'

type Props = {
  eventName: event_name
  pageData: IPage | null
  video_duration?: number
  ReferenceId?: string
  is_autoplay?: string
  page_has_video?: boolean
  platform?: Platform
}

type Platform = 'app' | 'desktop' | 'mobile'

type OTTProps = {
  eventName: event_name
  pageData?: IOTTPage | null | undefined
  video_duration?: string | number | null
  ReferenceId?: string | null
  is_autoplay?: string | null
  house_number?: string | null
  asset_id?: string
}

type SendData = {
  cd_reshet_id?: string
  page_id?: number
  page_type: string
  asset_id?: string
  page_title?: string
  clean_page_url?: string
  canonical_url?: string
  page_url?: string
  video_duration?: string | number | null
  ReferenceId?: string | null
  is_autoplay?: string | null
  house_number?: string | null
}

export function sendOTTGA4({ ...args }: OTTProps) {
  const { eventName, pageData } = args

  if (pageData === null || !pageData?.levels) {
    return false
  }

  const data: SendData = {
    ...pageData.levels,
    cd_reshet_id: pageData.cd_reshet_id,
    page_type: pageData.page_type,
    page_title: pageData.page_title,
    clean_page_url: pageData.clean_page_url,
    canonical_url: pageData.canonical_url,
    page_url: pageData.page_url,
  }
  if (args.house_number) {
    data.house_number = args.house_number
  }
  if (typeof pageData.page_id === 'number') {
    data.page_id = pageData.page_id
  }
  if (args.asset_id) {
    data.asset_id = args.asset_id
  }
  if (args.ReferenceId) {
    data.ReferenceId = args.ReferenceId
  }
  if (args.is_autoplay) {
    data.is_autoplay = args.is_autoplay
  }
  if (args.video_duration) {
    data.video_duration = args.video_duration
  }

  try {
    window.dataLayer.push({
      event: eventName,
      ...data,
    })
  } catch (e) {
    console.log(
      '%sendGA4:failed%',
      'background-color:darkred;color:white;padding:2px 5px;',
      e
    )
  }
}

export function sendGA4({ ...args }: Props) {
  const { eventName, pageData } = args

  if (pageData === null || !pageData.PageMeta.levels) {
    return
  }

  const {
    level_0_eng,
    level_0_heb,
    level_1_eng,
    level_1_heb,
    level_2_eng,
    level_2_heb,
    level_3_eng,
    level_3_heb,
    level_4_eng,
    level_4_heb,
    level_5_eng,
    level_5_heb,
    level_6_eng,
    level_6_heb,
  } = pageData.PageMeta.levels

  const { Content, SEO, PageMeta } = pageData
  const { id, publishDate, pageType } = PageMeta
  const title = SEO.title
  const writers = Content.Item?.writers
  const pageTags = Content.Item?.tags
  const editor = Content.Item?.editor
  const url = typeof window !== undefined ? window.location.href : ''
  const canonicalUrl = SEO.canonical
  const house_number = Content.Item?.video?.onAirID

  const cleanPageUrl = url && url.split('?')[0].split('#')[0]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = {
    //  eventModel
    page_1_level_eng: level_0_eng,
    page_1_level_heb: level_0_heb,
    page_2_level_eng: level_1_eng,
    page_2_level_heb: level_1_heb,
    page_3_level_eng: level_2_eng,
    page_3_level_heb: level_2_heb,
    page_4_level_eng: level_3_eng,
    page_4_level_heb: level_3_heb,
    page_5_level_eng: level_4_eng,
    page_5_level_heb: level_4_heb,
    page_6_level_eng: level_5_eng,
    page_6_level_heb: level_5_heb,
    page_7_level_eng: level_6_eng,
    page_7_level_heb: level_6_heb,
    page_id: id,
    page_publishdate: publishDate,
    clean_page_url: cleanPageUrl,
    page_url: url,
    canonical_url: canonicalUrl,
    page_type: pageType,
    cd_reshet_id: getUuid(),
    house_number: house_number,
    page_title: title ? title : '',
    page_writer: writers ? writers.map(({ name }) => name).join() : '',
    page_Tags: pageTags ? pageTags.map(({ name }) => name).join() : '',
    phase: 0,
    editor: editor,
  }

  for (const key in args) {
    if (key === 'eventName' || key === 'pageData') {
      continue
    }
    data[key] = args[key as keyof typeof args]
  }

  try {
    window.dataLayer.push({
      event: eventName,
      ...data,
    })
  } catch (e) {
    console.log(
      '%sendGA4:failed%',
      'background-color:darkred;color:white;padding:2px 5px;',
      e
    )
  }

  return 1
}
