/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IItem, IPageMeta, ITag, IVideo } from '../../../types'
import { ILevels } from '../Models/PlayerModels'
import { playerConfig } from './constants'

export const utils = (() => {
  return {
    isEmptyStr: (x: any) => {
      return !x
    },
    isLiveRef: (videoRef: string | number) => {
      if (!videoRef) {
        return false
      }
      if (typeof videoRef === 'number') {
        videoRef = videoRef.toString(10)
      }
      videoRef = videoRef.replace('ref:', '')
      const vr =
        videoRef as keyof typeof playerConfig.fakeWindow.siteConfig.liveRefId
      return playerConfig.fakeWindow.siteConfig.liveRefId[vr] !== undefined
    },
    isNews: () => {
      const pathNameArr = (window as any).location.pathname.split('/')
      return (
        pathNameArr[1] === 'news' ||
        (pathNameArr[1] === 'item' && pathNameArr[2] === 'news')
      )
    },
    isMobile: () => {
      if (typeof window !== 'undefined') {
        return (
          (window as any).device_type === 'mobile' ||
          (window as any).innerWidth < playerConfig.MobileBreakPoint ||
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
            navigator.userAgent
          )
        )
      } else {
        return false
      }
    },
    getParameterByName: (name: string, url?: string) => {
      if (typeof window !== 'undefined') {
        if (!url) {
          url = window.location.href
        }
        name = name.replace(/[[\]]/g, '\\$&')
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url)
        if (!results) {
          return ''
        }
        if (!results[2]) {
          return ''
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
      }
      return ''
    },

    isLivePage: (pageType: string) => pageType === 'pageLive',
    isLive: (videoRef: any, liveEntries?: any) => {
      if (!videoRef) {
        return false
      }
      if (typeof videoRef === 'number') {
        videoRef = videoRef.toString(10)
      }
      videoRef = videoRef.replace('ref:', '')

      return (
        playerConfig.fakeWindow.siteConfig.liveRefId[videoRef] !== undefined ||
        (liveEntries && liveEntries[videoRef] !== undefined)
      )
    },
    removeLinkPrefix: () => undefined, //TODO
    randomNumber: () => Math.floor(Math.random() * 100),
    appendScript: (src: string, attr?: any) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = src
        script.onload = resolve
        script.onerror = reject
        for (const prop in attr) {
          // eslint-disable-next-line no-prototype-builtins
          if (attr.hasOwnProperty(prop)) {
            script.setAttribute(prop, attr[prop])
          }
        }
        document.querySelector('head')?.appendChild(script)
      })
    },
    insertParam: (key: string, value: any, url: string) => {
      if (utils.isEmptyStr(url)) {
        return url
      }
      key = encodeURIComponent(key)
      value = encodeURIComponent(value)

      const s = url.split('?')
      const params = (s[1] || '')
        .split('&')
        .filter(el => el !== '')
        .map(el => {
          const split = el.split('=')
          return { key: split[0], value: split[1] }
        })
      params.push({ key: key, value: value })
      return (
        s[0] +
        params.reduce((ac, c) => {
          return ac + (ac === '' ? '?' : '&') + `${c.key}=${c.value}`
        }, '')
      )
    },
    getEnv: () => {
      if (playerConfig.fakeWindow.env !== undefined) {
        return playerConfig.fakeWindow.env
      }
      playerConfig.fakeWindow.env = 'local'
      if (typeof window !== 'undefined') {
        const origin = window.location.origin
        const devRegex = /next\.reshet\.tv/gi
        const stagingRegex = /next-pre\.reshet\.tv/gi
        const prodRegex = /((prod\.13tv\.co\.il)|(13tv\.co\.il))/gi
        if (origin.match(prodRegex)) {
          playerConfig.fakeWindow.env = 'prod'
        } else if (origin.match(stagingRegex)) {
          playerConfig.fakeWindow.env = 'pre'
        } else if (origin.match(devRegex)) {
          playerConfig.fakeWindow.env = 'dev'
        }
      } else {
        return playerConfig.fakeWindow.env
      }
    },
    isHome: () => {
      if (playerConfig.fakeWindow.isHome === undefined) {
        const parts = window.location.pathname.split('/')
        playerConfig.fakeWindow.isHome =
          window.location.pathname === '/' ||
          (utils.isNews() &&
            parts[1] === 'news' &&
            (parts.length === 2 || parts[2] === ''))
      }
    },
    getParamsString: (params: ITag[]) => {
      let tagStr = ''
      params.forEach((param: ITag, index) => {
        const comma = params.length > index + 1 ? ',' : ''
        tagStr += param.name + comma
      })
      return tagStr
    },
    coolaDataHandler: (
      levels: ILevels,
      pageMeta: IPageMeta,
      videoRef: string,
      video: IVideo,
      item: IItem | undefined
    ) => {
      const url = window.location.href
      let tagVal = ''
      let writerVal = ''
      if (item !== undefined) {
        tagVal = utils.getParamsString(item.tags)
        writerVal = utils.getParamsString(item.writers)
      }

      const cooladaConfig = {
        page_id: pageMeta.id,
        page_0_level_heb: levels.level_0_heb ? levels.level_0_heb : null,
        page_1_level_heb: levels.level_1_heb ? levels.level_1_heb : null,
        page_2_level_heb: levels.level_2_heb ? levels.level_2_heb : null,
        page_3_level_heb: levels.level_3_heb ? levels.level_3_heb : null,
        page_4_level_heb: levels.level_4_heb ? levels.level_4_heb : null,
        page_5_level_heb: levels.level_5_heb ? levels.level_5_heb : null,
        page_6_level_heb: levels.level_6_heb ? levels.level_6_heb : null,
        page_0_level_eng: levels.level_0_eng ? levels.level_0_eng : null,
        page_1_level_eng: levels.level_1_eng ? levels.level_1_eng : null,
        page_2_level_eng: levels.level_2_eng ? levels.level_2_eng : null,
        page_3_level_eng: levels.level_3_eng ? levels.level_3_eng : null,
        page_4_level_eng: levels.level_4_eng ? levels.level_4_eng : null,
        page_5_level_eng: levels.level_5_eng ? levels.level_5_eng : null,
        page_6_level_eng: levels.level_6_eng ? levels.level_6_eng : null,
        page_PublishDate: pageMeta.publishDate,
        page_Tags: tagVal,
        page_title: pageMeta.title,
        page_url: url,
        clean_page_url: url.split('?')[0],
        page_writer: writerVal,
        page_has_Video: pageMeta.pageType === 'item' && video,
        referenceid: videoRef,
        video_unavalable: false,
        error_code_kal: '',
      }
      return cooladaConfig
    },
    hideQualityMenu: (targetId: string) => {
      const playerId = '#' + targetId,
        playerContainer = document.querySelector(
          playerId + ' .kaltura-player-container'
        )
      if (playerContainer) {
        playerContainer.addEventListener('mouseleave', () => {
          const element: HTMLElement = document.querySelector(
            playerId + ' .playkit-control-button.playkit-active'
          ) as HTMLElement
          if (
            document.querySelector(
              playerId + ' .playkit-smart-container.playkit-top.playkit-left'
            )
          ) {
            element.click()
          }
        })
      }
    },
    hideBottomMenu: (target: HTMLElement) => {
      if (target.querySelector('.playkit-fullscreen')) {
        const element: HTMLElement = target.querySelector(
          '.playkit-bottom-bar'
        ) as HTMLElement
        element!.style.display = 'none !important'
        setTimeout(() => {
          element!.style.visibility = 'block !important'
        }, 500)
      }
    },
    outOfView: (target: HTMLElement) => {
      const res =
        target.getBoundingClientRect().bottom < 100 ||
        target.getBoundingClientRect().top >
          target.getBoundingClientRect().height * 2 + 150
      return res
    },
    isTaboolaElementInView: (target: HTMLElement) => {
      const position = target.getBoundingClientRect()
      return (
        position.height > 0 &&
        position.top < window.innerHeight &&
        position.bottom >= 0
      )
    },

    getMobileOperatingSystem: () => {
      const userAgent =
        navigator.userAgent || navigator.vendor || (window as any).opera
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }

      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && (window as any).WMSstrem) {
        return 'iOS'
      }

      return 'unknown'
    },
    getPageViewedInDay: () => {
      const day_view_str = localStorage.getItem('day_view')
      let dayBeginning: number
      let day_view: number[]
      let pageViewedInDay = 1
      if (day_view_str) {
        day_view = day_view_str.split(',').map(i => parseInt(i, 10))
        if (Date.now() - day_view[0] > playerConfig.NEW_DAY_TIMEOUT) {
          dayBeginning = Date.now()
          pageViewedInDay = 1
        } else {
          dayBeginning = day_view[0]
          pageViewedInDay = day_view[1] + 1
        }
      } else {
        dayBeginning = Date.now()
      }
      return {
        dayBeginning: dayBeginning,
        pageViewedInDay: pageViewedInDay,
      }
    },
    setPageViewedInDay: () => {
      const { dayBeginning, pageViewedInDay } = utils.getPageViewedInDay()
      localStorage.setItem(
        'day_view',
        [dayBeginning, pageViewedInDay].join(',')
      )
    },
    initPageViewedInSession: () => {
      const counterString = sessionStorage.getItem('session_view')
      const counter = counterString && parseInt(counterString, 10)
      if (!counter) {
        utils.setPageViewedInSession(0)
      }
    },
    getPageViewedInSession: () => {
      const counterString = sessionStorage.getItem('session_view')
      const counter = counterString && parseInt(counterString, 10)

      if (counter) {
        return counter
      }

      utils.setPageViewedInSession(0)
      return 0
    },
    setPageViewedInSession: (value: number) => {
      sessionStorage.setItem('session_view', value.toString())
    },
    dvrTimeComponent: (KalturaPlayer: any) => {
      const h = KalturaPlayer.ui.h
      const ComponentKal = KalturaPlayer.ui.preact.Component
      return class DvrTimeComponent extends ComponentKal {
        render(props: any) {
          const offset =
            this.context.player.duration - props.defaultPreviewProps.virtualTime
          const time = new Date(Date.now() - offset * 1000)
          const hours =
            time.getHours() > 9 ? time.getHours() : `0${time.getHours()}`
          const minutes =
            time.getMinutes() > 9 ? time.getMinutes() : `0${time.getMinutes()}`
          return h('div', { innerText: `${hours}:${minutes}`, ...props })
        }
      }
    },
    getViewOutsideWindow: () => {
      const outOfMainView = window.outerHeight * 0.35
      return window.scrollY >= outOfMainView
    },
    isScrolled: () => window.scrollY !== 0,
    addPageQueryParams: (
      path: string,
      pageId: number | undefined,
      id: number | undefined
    ) => {
      let queryToReturn = ''
      if (!pageId || !path) {
        return queryToReturn
      }

      queryToReturn += path.includes('?') ? '&' : '?'
      queryToReturn += pageId ? `pid=${pageId}` : ''
      queryToReturn += pageId && id ? `&cid=${id}` : ''
      return queryToReturn
    },

    _postMessage: (reqRes: string) => {
      const req = {
        action: reqRes,
      }
      const x = window?.webkit?.messageHandlers?.webToNative
      if (x) {
        x.postMessage(req)
      }
      try {
        window.postMessage(JSON.stringify(req))
      } catch (e) {
        console.log(
          '%cindex:313',
          'background-color:black;color:white;padding:2px 5px;',
          e
        )
      }
    },
    shuffle(array: []) {
      let currentIndex = array.length,
        randomIndex

      // While there remain elements to shuffle.
      while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ]
      }

      return array
    },
  }
})()
