export const addAnalyticsToUrl = (
  link: string,
  pageId: string,
  componentId: string
): string => {
  if (!link) {
    return ''
  }

  let searchParams = `pid=${pageId}`
  if (componentId) {
    searchParams += `&cid=${componentId}`
  }
  return link + (link.includes('?') ? '&' : '?') + searchParams
}
