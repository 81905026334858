import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { IPage } from '../../types'
import { webToNativeApi } from '../../client/webToNativeApi'
import { dfpUtils } from '../../components/Player/Helpers/dfpUtils'
import { utils } from '../../components/Player/Helpers/utils'
import { ottRoot } from '../../Ott/Api'
import { ILevels } from '../../components/Player/Models/PlayerModels'

// /**
//  * https://github.com/vercel/next.js/issues/4662
//  * Next.js router doesn't fire on fire page loads when there are no query string params
//  * This fixes it in order for the nativeApp to receive an event anyway
//  **/
let initialRouteTracked = false

const queue: { url: string; shallow: boolean }[] = []

let oldPath = ''
const shouldReportRouteChange = (newPath: string) => {
  const oldPathMatch = oldPath.match(/\/series\/(\d+)/),
    newPathMatch = newPath.match(/\/series\/(\d+)/)
  if (!newPathMatch || !oldPathMatch) {
    return true
  }
  if (newPathMatch.length > 1 && oldPathMatch.length > 1) {
    if (newPathMatch[1] === oldPathMatch[1]) {
      return false
    }
  }
  return true
}
/** Notifies the native app about route change events */
export function useNativeAppRouter(
  page: IPage | undefined,
  adTargetEnv: string
) {
  const router = useRouter()

  useEffect(() => {
    const routeChangeStart = (
      url: string,
      { shallow }: { shallow: boolean }
    ) => {
      oldPath = document.location.pathname
      if (shouldReportRouteChange(url) || !url.includes(ottRoot)) {
        webToNativeApi.routeChangeStart({
          url: url.replace('[[...all]]', ''),
          shallow,
        })
      }
    }
    while (queue.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { url, shallow } = queue.pop()!
      if (url !== router.asPath) {
        continue
      }
      triggerRouteChangeComplete({
        url: url.replace('[[...all]]', ''),
        shallow,
        page,
        adTargetEnv,
      })
    }

    const routeChangeComplete = (
      url: string,
      { shallow }: { shallow: boolean }
    ) => {
      // When performing internal navigation, the routeChangeComplete event gets triggered on the
      // old effect, with the old page state, we detect this by comparing the router's path and the
      // event url, when they're equal, this is a 'first load', and we have to trigger the event immediatly
      // otherwise, we push it to a queue, and the event will triggered when the hook is mounted again
      // with the new page state data

      if (router.asPath.replace('[[...all]]', '') === url) {
        triggerRouteChangeComplete({ url, shallow, adTargetEnv, page })
        return
      }
      queue.push({
        url: url.replace('[[...all]]', ''),
        shallow,
      })
    }
    const routeChangeError = (
      error: { cancelled: boolean },
      url: string,
      { shallow }: { shallow: boolean }
    ) => {
      webToNativeApi.routeChangeError({
        error,
        url,
        shallow,
      })
    }
    router.events.on('routeChangeStart', routeChangeStart)
    router.events.on('routeChangeComplete', routeChangeComplete)
    router.events.on('routeChangeError', routeChangeError)

    if (!initialRouteTracked && !router.asPath.includes('[[...all]]')) {
      initialRouteTracked = true
      routeChangeStart(router.asPath, {
        shallow: true,
      })
      routeChangeComplete(router.asPath, {
        shallow: true,
      })
    }

    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      router.events.off('routeChangeComplete', routeChangeComplete)
      router.events.off('routeChangeError', routeChangeError)
    }
  }, [adTargetEnv, page, router.asPath, router.events, router.pathname])
}

const triggerRouteChangeComplete = ({
  url,
  shallow,
  page,
  adTargetEnv,
}: {
  url: string
  shallow: boolean
  page: IPage | undefined
  adTargetEnv: string
}) => {
  const shouldShowMaavaron = page?.PageMeta
    ? !!page?.PageMeta?.ads?.block?.shouldShowMaavaron
    : true

  const levels = (page?.PageMeta?.levels || {}) as ILevels
  if (!levels.level_0_eng) {
    const tempLevels = url.split('/').filter(p => !!p)
    tempLevels.map((l, i) => {
      levels[`level_${i + 1}_eng` as keyof ILevels] = l
      levels[`level_${i + 1}_heb` as keyof ILevels] = l
    })
  }
  let title = page?.PageMeta?.title
  if (!title) {
    setTimeout(() => {
      title = document.title
      if (shouldReportRouteChange(url) || !url.includes(ottRoot)) {
        webToNativeApi.routeChangeComplete({
          url: url,
          shallow: shallow,
          title: title,
          pageType: url.includes(ottRoot)
            ? 'gridvod'
            : page?.PageMeta?.pageType,
          applicationPageType: url.includes(ottRoot)
            ? 'vod'
            : page?.PageMeta?.applicationPageType,
          custParams: dfpUtils.buildCustParams(
            page?.PageMeta?.pageType,
            levels,
            url,
            page?.Content?.Item?.tags,
            adTargetEnv
          ),
          shouldShowMaavaron:
            shouldShowMaavaron &&
            shouldShowAppInterstitial.get(
              page?.SiteMeta.config?.maavaron?.app || 3
            ),
        })
      }
    }, 200)
  } else {
    webToNativeApi.routeChangeComplete({
      url: url,
      shallow: shallow,
      title: title,
      pageType: url.includes(ottRoot) ? 'gridvod' : page?.PageMeta?.pageType,
      applicationPageType: url.includes(ottRoot)
        ? 'vod'
        : page?.PageMeta?.applicationPageType,
      custParams: dfpUtils.buildCustParams(
        page?.PageMeta?.pageType,
        levels,
        url,
        page?.Content?.Item?.tags,
        adTargetEnv
      ),
      shouldShowMaavaron:
        shouldShowMaavaron &&
        shouldShowAppInterstitial.get(
          page?.SiteMeta.config?.maavaron?.app || 3
        ),
    })
  }
}

const shouldShowAppInterstitial = {
  get: (intervals: number) =>
    (utils.getPageViewedInSession() - 1) % intervals === 0,
}
