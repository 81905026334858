import React, { createContext } from 'react'
import { IPage } from '../../types'

export const PageContext = createContext<IPage | undefined>(undefined)

type Props = {
  children: React.ReactNode
  page: IPage
}

export const PageProvider = ({ children, page }: Props) => {
  return <PageContext.Provider value={page} children={children} />
}
