import axios from 'axios'
import { clientConfig } from '../client/clientConfig'
import { asset_type_enum } from './Components/API/API'
import { config } from '../lib'

export const ottRoot = '/allshows'
const apiUrl = `${clientConfig.kaltura_end_point}asset/action/list`
export const fetchRailData = async ({
  railId,
  ks,
}: {
  railId: number | undefined
  ks: string
}) => {
  const response = await axios.post(apiUrl, {
    filter: { objectType: 'KalturaChannelFilter', idEqual: railId, kSql: '' },
    pager: { objectType: 'KalturaFilterPager', pageIndex: 1, pageSize: 25 },
    format: 1,
    clientTag: config.kalturaClientTag,
    apiVersion: '6.1.0.28839',
    language: 'heb',
    ks: ks,
  })
  return response.data.result.objects
}

export type ISearchParams = {
  typeValue: number
  searchQuery: string
  pageSize: number
  pageIndex?: number
  ks: string
}

export const SearchApi = async ({
  pageIndex = 1,
  typeValue,
  searchQuery,
  pageSize,
  ks,
}: ISearchParams) => {
  try {
    let ksql: string
    switch (typeValue) {
      case asset_type_enum.Series:
      case asset_type_enum.Episodes:
        ksql = `(or (and (or asset_type='${typeValue}') (or name*'${searchQuery} 'name~'${searchQuery} 'Keywords*'${searchQuery} 'Genre*'${searchQuery} 'SubGenre*'${searchQuery}')))`
        break
      default:
        ksql = `(or (and (or asset_type='${typeValue}') (or name*'${searchQuery} 'name~'${searchQuery} 'director*'${searchQuery} 'Keywords*'${searchQuery} 'Actors*'${searchQuery} 'Genre*'${searchQuery} 'SubGenre*'${searchQuery}')) (and asset_type='epg' start_date<'0' (or name*'${searchQuery} 'name~'${searchQuery} 'director*'${searchQuery} 'Keywords*'${searchQuery} 'Genre*'${searchQuery} 'SubGenre*'${searchQuery} 'Actors*'${searchQuery}')))`
    }
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        apiVersion: '6.3.0',
        clientTag: config.kalturaClientTag,
        format: 1,
        ks: ks,
        filter: {
          kSql: ksql,
          name: searchQuery,
          objectType: 'KalturaSearchAssetFilter',
          orderBy: 'RELEVANCY_DESC',
          typeIn: typeValue,
        },
        pager: {
          objectType: 'KalturaFilterPager',
          pageSize: pageSize,
          pageIndex: pageIndex,
        },
      }),
    })

    if (!response.ok) {
      console.error(`Failed to fetch. Status: ${response.status}`)
      return
    }
    const data = await response.json()
    if (data?.result?.objects) {
      return data
    }
  } catch (error) {
    console.error('Error making API call:', error)
  }
  return null
}
