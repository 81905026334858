import { isNativeApp } from './isNativeApp'
import { size } from '../styles/GlobalVariables'

export function getAdDeviceType() {
  return window.innerWidth < size.medium
    ? isNativeApp()
      ? 'app'
      : 'mobile'
    : 'desktop'
}

export const isDesktop = () => getAdDeviceType() === 'desktop'

export const isMobile = () => !isDesktop()
