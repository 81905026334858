import { ICustParams } from '../components/Player/Helpers/dfpUtils'
import { WebToNative, webToNative } from './webToNative'

const postMessage: WebToNative['postMessage'] = req => {
  console.log(
    'webToNative',
    'postMessage',
    req,
    'iframe =',
    window !== window.top
  )
  webToNative.postMessage(req)
}
export const webToNativeApi: WebToNativeApi = {
  routeChangeStart: args => {
    postMessage({
      action: 'routeChangeStart',
      args,
    })
  },
  routeChangeComplete: args => {
    postMessage({
      action: 'routeChangeComplete',
      args,
    })
  },
  routeChangeError: args => {
    postMessage({
      action: 'routeChangeError',
      args,
    })
  },
}

export interface WebToNativeApi {
  routeChangeStart(args: { url: string; shallow: boolean }): void
  routeChangeComplete(args: {
    url: string
    shallow: boolean
    title: string | undefined
    pageType: string | undefined
    applicationPageType: string | undefined
    custParams: ICustParams
    shouldShowMaavaron: boolean | undefined
  }): void
  routeChangeError(args: {
    error: { cancelled: boolean }
    url: string
    shallow: boolean
  }): void
}
